import Wrapper from './_views/Wrapper.vue';

const IrFulfilments = () => import('./_views/IrFulfilments.vue');

export default [
  {
    path: '/fulfilments',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'fulfilments',
        component: IrFulfilments,
        meta: {
          title: 'Fulfilment',
          navigation: {
            position: 6,
            name: 'Fulfilment',
            icon: 'timer',
            to: {
              name: 'fulfilments',
              query: {
                pageNum: '1',
              },
            },
          },
        },
      },
    ],
  },
];
