export const wrapToLoadingFn = async function wrapToLoadingFn({
  req, onSuccess, onReject, onFinally,
  loadingFlagName, loadingSpinnerType,
  withLoadingSpinner = true,
}) {
  if (loadingFlagName) {
    this[loadingFlagName] = true;
  } else if (withLoadingSpinner) {
    this.$VBlackerTheme.openLoadingSpinner(loadingSpinnerType);
  }

  try {
    const response = await req();
    if (onSuccess) {
      await onSuccess(response);
    }

    return response;
  } catch (e) {
    if (onReject) {
      onReject(e);
    } else {
      this.$VBlackerTheme.notification.error(e.msg || `${e}`);
    }

    return Promise.reject(e);
  } finally {
    if (onFinally) {
      onFinally();
    } else if (loadingFlagName) {
      this[loadingFlagName] = false;
    } else if (withLoadingSpinner) {
      this.$VBlackerTheme.closeLoadingSpinner();
    }
  }
};

export default {};
