import { CancelToken } from 'axios';

import HTTP from '@api';

import { generateXHRError } from '@helpers';

let cancelGetMsbcSalesOrders = () => {};
let cancelGetMsbcPurchaseOrders = () => {};
let cancelGetMsbcInvoices = () => {};

export default {
  namespaced: true,

  state: {
    msbcSalesOrders: [],
    msbcPurchaseOrders: [],
    msbcInvoices: [],
  },

  mutations: {
    setMsbcSalesOrders(state, payload) {
      state.msbcSalesOrders = payload;
    },

    setMsbcPurchaseOrders(state, payload) {
      state.msbcPurchaseOrders = payload;
    },

    setMsbcInvoices(state, payload) {
      state.msbcInvoices = payload;
    },
  },

  actions: {
    async getMsbcSalesOrders({ commit }, {
      orderId = null, accountId = null, dates = null,
      number = null, pageNum = 1, pageSize = 10,
    }) {
      cancelGetMsbcSalesOrders();
      commit('setMsbcSalesOrders', []);

      try {
        const { data: { items } } = await HTTP.get('msbc/sales-orders', {
          params: {
            orderId,
            accountId,
            number,
            startDate: dates && dates.length ? dates[0] : null,
            endDate: dates && dates.length ? `${dates[1]} 23:59:59` : null,
            pageNum,
            pageSize,
          },
          cancelToken: new CancelToken((c) => {
            cancelGetMsbcSalesOrders = c;
          }),
        });

        commit('setMsbcSalesOrders', items || []);

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },

    async getMsbcPurchaseOrders({ commit }, {
      orderId = null, accountId = null, dates = null,
      number = null, pageNum = 1, pageSize = 10,
    }) {
      cancelGetMsbcPurchaseOrders();
      commit('setMsbcPurchaseOrders', []);

      try {
        const { data: { items } } = await HTTP.get('msbc/purchase-orders', {
          params: {
            orderId,
            accountId,
            number,
            startDate: dates && dates.length ? dates[0] : null,
            endDate: dates && dates.length ? `${dates[1]} 23:59:59` : null,
            pageNum,
            pageSize,
          },
          cancelToken: new CancelToken((c) => {
            cancelGetMsbcPurchaseOrders = c;
          }),
        });

        commit('setMsbcPurchaseOrders', items || []);

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },

    async getMsbcInvoices({ commit }, {
      accountId = null, dates = null, number = null,
      pageNum = 1, pageSize = 10,
    }) {
      cancelGetMsbcInvoices();
      commit('setMsbcInvoices', []);

      try {
        const { data: { items } } = await HTTP.get('msbc/invoices', {
          params: {
            accountId,
            number,
            startDate: dates && dates.length ? dates[0] : null,
            endDate: dates && dates.length ? `${dates[1]} 23:59:59` : null,
            pageNum,
            pageSize,
          },
          cancelToken: new CancelToken((c) => {
            cancelGetMsbcInvoices = c;
          }),
        });

        commit('setMsbcInvoices', items || []);

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
  },
};
