export const mapAccountsToOptions = accounts => accounts
  .map(account => ({
    text: (!account.accountFullName || !account.accountName)
      ? (account.accountFullName || account.accountName)
      : `${account.accountFullName} (${account.accountName})`,
    value: account.accountId,
  }))
  .sort(({ text: a }, { text: b }) => {
    const aStr = a.slice(0, 1).toLowerCase();
    const bStr = b.slice(0, 1).toLowerCase();

    if (aStr < bStr) {
      return -1;
    }

    if (aStr > bStr) {
      return 1;
    }

    return 0;
  });

const CLIENTS_ACCOUNT_TYPE = 1;
export const getClients = accounts => accounts
  .filter(({ accountType }) => accountType === CLIENTS_ACCOUNT_TYPE);

const PROVIDERS_ACCOUNT_TYPE = 2;
export const getProviders = accounts => accounts
  .filter(({ accountType }) => accountType === PROVIDERS_ACCOUNT_TYPE);
