import HTTP from '@api';

import { generateXHRError } from '@helpers';

export default {
  namespaced: true,
  state: {
    subscriptions: [],
  },
  mutations: {
    setTrackingSubscriptions(state, payload) {
      state.subscriptions = payload;
    },
  },
  actions: {
    async getTrackingSubscriptions({ commit }, {
      orderId = null, trackingNumber = null, isActive = null,
      pageNum = 1, pageSize = 10,
    }) {
      try {
        const { data = {} } = await HTTP.get('tracking/subscriptions', {
          params: {
            orderId, trackingNumber, isActive, pageNum, pageSize,
          },
        });
        commit('setTrackingSubscriptions', data.items || []);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
  },
};
