export default [
  {
    path: '/settings/access-journal',
    name: 'accessJournal',
    component: () => import('./_views/IrAccessJournal.vue'),
    meta: {
      title: 'Access Journal',
      group: 'settings',
      navigation: {
        name: 'Access Journal',
        to: {
          name: 'accessJournal',
        },
      },
    },
  },
];
