const Dashboard = () => import('./_views/dashboard');

export default [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      navigation: {
        position: 1,
        exact: true,
        name: 'Dashboard',
        icon: 'dashboard',
        to: {
          name: 'dashboard',
        },
      },
    },
  },
];
