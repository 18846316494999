const Messages = () => import('./_views/messages');

export default [
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
    meta: {
      title: 'Messages',
      navigation: {
        position: 4,
        exact: true,
        name: 'Messages',
        icon: 'chat',
        to: {
          name: 'messages',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
