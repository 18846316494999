<template>
  <span v-if="!value || notConvertable">
    <slot>
      {{ value || '-' }}
    </slot>
  </span>
  <v-tooltip
    v-else
    color="#121212"
    top
  >
    <template #activator="{ on }">
      <span
        class="currency-title"
        v-on="on"
      >
        <slot>
          {{ convertedInTooltip ? value : currentValue }} {{ currentCurrency }}
        </slot>
      </span>
    </template>
    <v-layout column>
      <v-layout v-if="convertDate">
        Convert date:
        <strong class="warning--text ml-1">
          {{ convertDate | formatDate('local') }}
        </strong>
      </v-layout>
      <v-layout align-center>
        Converted from
        <strong class="error--text mx-1">
          {{ baseCurrency }}
        </strong>
        to
        <strong class="warning--text mx-1">
          {{ currentCurrency }}
        </strong>
      </v-layout>
      <v-layout align-center>
        Original value:
        <strong class="error--text ml-1">
          {{ convertedInTooltip ? currentValue : value }}
        </strong>
      </v-layout>
      <v-layout v-if="provider">
        Rate provider:
        <strong class="warning--text ml-1">
          {{ provider }}
        </strong>
      </v-layout>
      <v-layout align-center>
        Rate:
        <strong class="warning--text ml-1">
          {{ rate }}
        </strong>
      </v-layout>
    </v-layout>
  </v-tooltip>
</template>

<script>
import { formatNumber } from '@helpers';

export default {
  name: 'IrCurrency',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    rate: {
      type: Number,
      default: 1,
    },
    provider: {
      type: String,
      default: '',
    },
    baseCurrency: {
      type: String,
      default: '',
    },
    currentCurrency: {
      type: String,
      default: '',
    },
    convertDate: {
      type: [String, Number, Date],
      default: '',
    },
    deconvert: Boolean,
    convertedInTooltip: Boolean,
  },
  computed: {
    notConvertable() {
      const { currentCurrency: cc, baseCurrency: bc } = this;

      return !cc || !bc;
    },
    currentValue() {
      const { deconvert, value, rate } = this;
      return formatNumber(deconvert ? value / rate : value * rate);
    },
  },
};
</script>

<style lang="css" scoped>
.currency-title {
  text-decoration: underline dashed;
  text-underline-position: under;
}

.currency-title:hover {
  cursor: help;
}
</style>
