import Wrapper from './_views/Wrapper.vue';

const ReturnedPackages = () => import('./_views/ReturnedPackages.vue');
const ReturnedPackage = () => import('./_views/ReturnedPackage.vue');

const parentRouteConfig = {
  name: 'Returned Packages',
  to: {
    name: 'returnedPackages',
    query: {
      pageNum: '1',
    },
  },
};

export default [
  {
    path: '/returned-packages',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'returnedPackages',
        component: ReturnedPackages,
        meta: {
          title: 'Returned Packages',
          group: 'shipping',
          navigation: {
            name: 'Returned Packages',
            to: {
              name: 'returnedPackages',
              query: {
                pageNum: '1',
              },
            },
          },
        },
      },
      {
        path: ':id',
        name: 'returnedPackage',
        component: ReturnedPackage,
        meta: {
          title: 'Returned Package',
          parent: parentRouteConfig,
        },
      },
    ],
  },
];
