export default [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('./_views/IrReports.vue'),
    meta: {
      title: 'Reports',
      navigation: {
        position: 7,
        name: 'Reports',
        icon: 'description',
        to: {
          name: 'reports',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
