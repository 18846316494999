import HTTP from '@api';

import { generateXHRError } from '@helpers';

export default {
  namespaced: true,
  state: {
    routingRule: {},
    routingRuleCode: '',
    routingRuleUpdateHistory: [],
    routingRuleUpdateHistoryTotalCount: 0,
  },
  mutations: {
    setRoutingRule(state, payload) {
      state.routingRule = payload;
    },
    setRoutingRuleCode(state, payload) {
      state.routingRuleCode = payload;
    },
    setRoutingRuleUpdateHistory(state, payload) {
      state.routingRuleUpdateHistory = payload;
    },
    setRoutingRuleUpdateHistoryTotalCount(state, payload) {
      state.routingRuleUpdateHistoryTotalCount = payload;
    },
  },
  actions: {
    async getRoutingRule({ commit }) {
      try {
        const { data = {} } = await HTTP.get('rules');
        commit('setRoutingRule', data || {});
        commit('setRoutingRuleCode', data.rules || '');
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
    async updateRoutingRule({ commit }, { rules, reason }) { // eslint-disable-line
      try {
        await HTTP.post('rules', { rules, reason });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
    async getRoutingRuleUpdateHistory({ commit }, { pageNum = 1, pageSize = 10 }) {
      commit('setRoutingRuleUpdateHistoryTotalCount', 0);
      try {
        const { data: { items, total = 0 } = {} } = await HTTP.get('rules/history', {
          params: { pageNum, pageSize },
        });
        commit('setRoutingRuleUpdateHistoryTotalCount', total);
        commit('setRoutingRuleUpdateHistory', items || []);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
  },
};
