const IrMsbc = () => import('./_views/irMsbc');

export default [
  {
    path: '/costs/msbc',
    name: 'msbc',
    component: IrMsbc,
    meta: {
      title: 'MSBC',
      group: 'costs',
      navigation: {
        name: 'MSBC',
        to: {
          name: 'msbc',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
