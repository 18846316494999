const Estimations = () => import('./_views/estimations');
const Estimation = () => import('./_views/Estimation.vue');

export default [
  {
    path: '/shipping/estimations',
    name: 'estimations',
    component: Estimations,
    meta: {
      title: 'Estimations',
      group: 'shipping',
      navigation: {
        name: 'Estimations',
        to: {
          name: 'estimations',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
  {
    path: '/shipping/estimations/:id',
    name: 'estimation',
    component: Estimation,
    meta: {
      title: 'Estimation',
      group: 'shipping',
      parent: {
        name: 'Estimations',
        to: {
          name: 'estimations',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
