import { ReturnApiService } from './_services/return.api.service';

import { createReturnedOrder } from './_meta/returnedOrder';

export default {
  namespaced: true,

  state: {
    returnedOrder: [createReturnedOrder()],
  },

  mutations: {
    setReturnedOrder(state, payload) {
      state.returnedOrder = payload;
    },
  },

  actions: {
    async getReturnedOrder({ commit }, params) {
      commit('setReturnedOrder', await ReturnApiService.getReturnedOrder({ ...params }));
    },
  },
};
