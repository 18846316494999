import Vue from 'vue';

import VBlackerTheme, { VBlackerThemeProvider } from 'v-blacker-theme';
import VCharts from 'v-charts';

import { format, parseISO } from 'date-fns';

import { createRouter } from '@router';

import store from '@store';

import IrNotification from '@components/IrNotification.vue';
import IrStateIcon from '@components/IrStateIcon.vue';
import IrCurrency from '@components/IrCurrency.vue';

import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(VBlackerTheme);
Vue.use(VCharts);

Vue.filter('formatDate', (date, type, defaultValue, formatStr) => {
  if (!date) {
    return defaultValue || '';
  }
  // dirty hack for detect UTC-0
  // used while not fixed dates in backend
  // remove when will be used one format for UTC dates
  let dateUTC = new Date(date.toLowerCase().endsWith('z') ? date : `${date}Z`);
  if (dateUTC.toString().toLowerCase() === 'invalid date') {
    dateUTC = new Date(date);
  }
  const dateParsedISO = parseISO(date);
  if (type === 'local') {
    return format(dateUTC, formatStr || 'yyyy-MM-dd, hh:mm a');
  }
  return format(dateParsedISO, formatStr || 'yyyy-MM-dd, hh:mm a');
});

Vue.component('ir-notification', IrNotification);
Vue.component('ir-state-icon', IrStateIcon);
Vue.component('ir-currency', IrCurrency);

const router = createRouter();
const app = new Vue({
  store,
  router,
  ...VBlackerThemeProvider,
  render: h => h(App),
});

router.onReady(() => app.$mount('#app'));
