import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const FulfilmentApiService = {
  async getFulfilments({
    type = null, status = null, orderId = null,
    printCustomerId = null, printProviderId = null, lateShipment = null, notDeliveredInTime = null,
    pageNum = 1, pageSize = 10,
  }) {
    try {
      const { data } = await HTTP.get('fulfilments', {
        params: {
          type,
          status,
          orderId,
          printCustomerId,
          printProviderId,
          lateShipment,
          notDeliveredInTime,
          pageNum,
          pageSize,
        },
      });

      return Promise.resolve(data || []);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
