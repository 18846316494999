import LabelsApiService from './_services/labels.api.services';

import { SET_LABELS_INFORMATION, GET_LABELS_INFORMATION } from './store.types';

export { LABELS_INFORMATION_NAMESPACE as namespace } from './store.types';

export default {
  namespaced: true,
  state: {
    labels: [],
  },
  mutations: {
    [SET_LABELS_INFORMATION](state, payload) {
      state.labels = payload;
    },
  },
  actions: {
    async [GET_LABELS_INFORMATION]({ commit }, params = {}) {
      commit(SET_LABELS_INFORMATION, await LabelsApiService.getLabelsInformation(params));
    },
  },
};
