<template>
  <v-icon
    :color="state ? 'success' : 'error'"
    small
  >
    {{ state ? 'check' : 'block' }}
  </v-icon>
</template>

<script>
export default {
  name: 'IrStateIcon',

  props: {
    state: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
