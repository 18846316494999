import HTTP from '@api';

import { generateXHRError } from '@helpers';

export default {
  namespaced: true,

  state: {
    invoiceRecords: [],
    invoiceRecordHistory: [],
  },

  mutations: {
    setInvoiceRecords(state, payload) {
      state.invoiceRecords = payload;
    },

    setInvoiceRecordHistory(state, payload) {
      state.invoiceRecordHistory = payload;
    },
  },

  actions: {
    async getInvoiceRecords({ commit }, {
      customerId = null, providerId = null, orderId = null,
      zeroCost = null, zeroShipCost = null, zeroEstimatedShipCost = null,
      dates = null, pageNum = 1, pageSize = 10,
    }) {
      try {
        const { data = {} } = await HTTP.get('invoices/records', {
          params: {
            customerId,
            providerId,
            orderId,
            zeroCost: zeroCost || null,
            zeroShipCost: zeroShipCost || null,
            zeroEstimatedShipCost: zeroEstimatedShipCost || null,
            startDate: dates && dates.length ? dates[0] : null,
            endDate: dates && dates.length ? `${dates[1]} 23:59:59` : null,
            pageNum,
            pageSize,
          },
        });
        commit('setInvoiceRecords', data.items || []);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },

    async getInvoiceRecordHistory({ commit }, id) {
      try {
        const { data } = await HTTP.get(`invoices/records/${id}/history`);
        commit('setInvoiceRecordHistory', data || []);

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
  },
};
