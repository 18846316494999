import HTTP from '@api';

import { generateXHRError } from '@helpers';

const remapToKeyValueObj = items => items.reduce((acc, { value, name }) => {
  acc[value] = name;

  return acc;
}, {});

export default {
  namespaced: true,
  state: {
    enums: {},
  },
  mutations: {
    setEnums(state, payload) {
      state.enums = payload;
    },
  },
  actions: {
    async getEnums({ commit }) {
      try {
        const { data = {} } = await HTTP('enums');
        commit('setEnums', data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
  },
  getters: {
    accountTypesByTypeId: ({
      enums: { accountTypes: items = [] },
    }) => remapToKeyValueObj(items),

    msbcOrderStatusesByTypeId: ({
      enums: { msbcOrderStatuses: items = [] },
    }) => remapToKeyValueObj(items),

    msbcInvoiceTypesByTypeId: ({
      enums: { msbcInvoiceTypes: items = [] },
    }) => remapToKeyValueObj(items),

    complaintActionsByActionId: ({
      enums: { complaintActions: items = [] },
    }) => remapToKeyValueObj(items),

    complaintStatusByStatusId: ({
      enums: { complaintStatuses: items = [] },
    }) => remapToKeyValueObj(items),

    fulfulmentStatusByStatusId: ({
      enums: { fulfilmentStatuses: items = [] },
    }) => remapToKeyValueObj(items),

    complaintReviewStatusByStatusId: ({
      enums: { complaintReviewStatuses: items = [] },
    }) => remapToKeyValueObj(items),

    estimationErrorsByTypeId: ({
      enums: { estimationErrors: items = [] },
    }) => remapToKeyValueObj(items),

    msbcInvoiceStatusesByStatusKey: ({
      enums: { msbcInvoiceStatuses: items = [] },
    }) => remapToKeyValueObj(items),

    statementStatesStateEnum: ({
      enums: { statementStates: items = [] },
    }) => remapToKeyValueObj(items),

    invoiceStatesStateEnum: ({
      enums: { invoiceStates: items = [] },
    }) => remapToKeyValueObj(items),

    invoiceSchedulerStatesEnum: ({
      enums: { invoiceScheduleHistoryState: items = [] },
    }) => remapToKeyValueObj(items),

    mailingConfirmationProcessingStateByEnum: ({
      enums: { statementStates: items = [] },
    }) => remapToKeyValueObj(items),
  },
};
