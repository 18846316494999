import { format, parseISO } from 'date-fns';

const defaultDateTimeFormat = 'yyyy-MM-dd kk:mm a';
const defaultDateFormat = 'yyyy-MM-dd';

export const formatDisplayDate = (date, {
  type = 'local', defaultValue = '-', dateFormat = defaultDateTimeFormat,
} = {}) => {
  if (!date) {
    return defaultValue;
  }

  const dateParsedISO = parseISO(date.replace('Z', ''));
  // dirty hack for detect UTC-0
  // used while not fixed dates in backend
  // remove when will be used one format for UTC dates
  let dateUTC = new Date(date.toLowerCase().endsWith('z') ? date : `${date}Z`);
  if (dateUTC.toString().toLowerCase() === 'invalid date') {
    dateUTC = new Date(date);
  }

  return format(type === 'local' ? dateUTC : dateParsedISO, dateFormat);
};

export const formatDisplayDateOnly = (date, {
  type = 'local', defaultValue = '-',
} = {}) => formatDisplayDate(date, { dateFormat: defaultDateFormat, type, defaultValue });

export const getDatesRangeByLastDays = (daysCount = 1, dateFormat = defaultDateFormat) => {
  const today = new Date();
  const lastDays = new Date(today.setDate(today.getDate() - daysCount));

  return [
    format(lastDays, dateFormat),
    format(new Date(), dateFormat),
  ];
};

export const getDatesRangeByLastMonth = ({
  initialDate = null,
  dateFormat = defaultDateFormat,
} = {}) => {
  const today = initialDate ? new Date(initialDate) : new Date();

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return [
    format(firstDayOfMonth, dateFormat),
    format(lastDayOfMonth, dateFormat),
  ];
};

export const getAllowedDatePickerRange = ({ nextDaysCount = 1, prevYearsCounts = 2 } = {}) => {
  const today = new Date();
  const nextDays = today.setDate(today.getDate() + nextDaysCount);

  return {
    min: `${(new Date()).getFullYear() - prevYearsCounts}-01-01`,
    max: (new Date(nextDays)).toISOString(),
  };
};

export const getDatesRangeObjFromDatesArray = (dates, {
  startDateName = 'startDate', endDateName = 'endDate',
} = {}) => {
  if (!dates || !dates.length || !(dates[0] || dates[1])) {
    throw new Error(`Invalid serialized dates: ${JSON.stringify(dates)}`);
  }

  return {
    [startDateName]: dates[0],
    [endDateName]: `${dates[1]} 23:59:59`,
  };
};

export const getThisWeekRange = (dateFormat = defaultDateFormat) => {
  const today = new Date();
  const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));

  return [
    format(firstDay, dateFormat),
    format(new Date(), dateFormat),
  ];
};

export const getThisMonthRange = (dateFormat = defaultDateFormat) => {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  return [
    format(firstDay, dateFormat),
    format(new Date(), dateFormat),
  ];
};

export const getLastWeekRange = (dateFormat = defaultDateFormat) => {
  const today = new Date();
  const lastWeekDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  const firstDay = new Date(lastWeekDay.setDate(lastWeekDay.getDate() - lastWeekDay.getDay()));
  const dateForLast = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  const lastDay = new Date(dateForLast.setDate(dateForLast.getDate() + (6 - dateForLast.getDay())));

  return [
    format(firstDay, dateFormat),
    format(lastDay, dateFormat),
  ];
};

export const getLastMonthRange = (dateFormat = defaultDateFormat) => {
  const today = new Date();
  today.setDate(1);
  today.setMonth(today.getMonth() - 1);
  const firstDay = new Date(today);

  return [
    format(firstDay, dateFormat),
    format((new Date()).setDate(0), dateFormat),
  ];
};

export const diffDatesHours = (date1, date2) => {
  const diffTime = Math.abs(new Date(date1) - new Date(date2));
  return Math.ceil(diffTime / (1000 * 60 * 60));
};

const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December',
];
export const getMonthName = monthNumber => MONTH_NAMES[monthNumber];

export default {};
