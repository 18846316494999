import HTTP from '@api';

import { generateXHRError } from '@helpers';

import { createReshipOrder } from '../_meta/reshipOrder';

export const ReshipApiService = {
  async getReshipOrderProviders({ searchTerm }) {
    try {
      const { data = [createReshipOrder()] } = await HTTP.get(`operations/${searchTerm}/reship`);

      data.forEach((item) => {
        item.reference = item.printProviderOrderId;
      });

      return data || [createReshipOrder()];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async sendReshipOrder({ orderId, form }) {
    try {
      const { data } = await HTTP.post(`operations/${orderId}/reship`, form);

      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
