const TestRouting = () => import('./_views/testRouting');

export default [
  {
    path: '/settings/test-routing',
    name: 'testRouting',
    component: TestRouting,
    meta: {
      title: 'Test Routing',
      group: 'settings',
      navigation: {
        name: 'Test Routing',
        to: {
          name: 'testRouting',
        },
      },
    },
  },
];
