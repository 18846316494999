import { mapState, mapGetters, mapActions } from 'vuex';

import { PROJECT_NAME } from '@config/application';
import serverUrl from '@config/server';
import generateNavigation from '@config/navigation';
import generateRouteInfo, { MAIN_ROUTE_NAME } from '@config/routeInformation';

import { createTicker, decodeJWT } from '@helpers';

import { ReportsStatusService } from '@modules/reports';

import { version as uiVersion } from '../../../package.json';

import OrdersTopSearch from '../ordersTopSearch';

const ReportsStatusServiceTicker = createTicker();

export default {
  name: 'AppWrapper',

  data() {
    return {
      reportsNotifications: [],

      uiVersion,
      serverUrl,
      copyright: `© Opensoft - ${new Date().getFullYear()}`,
    };
  },
  components: {
    OrdersTopSearch,
  },
  computed: {
    ...mapState('authenticator', ['token', 'forbidden']),
    ...mapState('application', ['apiVersion']),
    ...mapGetters('application', ['userName']),
    projectTitle() {
      return {
        name: PROJECT_NAME,
        to: {
          name: 'dashboard',
        },
      };
    },
    needMainApp() {
      const { name } = this.$route;
      return name !== 'error404' && name !== 'forbidden' && name !== 'login' && this.token;
    },
    navigationConfig() {
      return generateNavigation(this.$router.options.routes, this.$route);
    },
    routeInfoConfig() {
      const { routes = [] } = this.$router.options;
      const MAIN_ROUTE = routes.find(route => route.name === MAIN_ROUTE_NAME) || {};
      const MAIN_ROUTE_CONFIG = {
        name: MAIN_ROUTE_NAME,
        title: MAIN_ROUTE.meta.title,
        to: MAIN_ROUTE.meta.navigation.to,
      };
      return generateRouteInfo(this.$route, MAIN_ROUTE_CONFIG);
    },
    bugReportInfo() {
      const { name, email } = decodeJWT(this.token);
      return {
        projectName: PROJECT_NAME,
        uiVersion: this.uiVersion,
        apiVersion: this.apiVersion,
        url: window.location.href,
        username: name,
        email,
      };
    },
  },

  created() {
    this.initApp();
  },

  methods: {
    ...mapActions('authenticator', ['logout']),
    ...mapActions('application', ['getSettings']),
    ...mapActions('enums', ['getEnums']),
    initApp() {
      this.getSettings();

      if (this.token) {
        this.runReportsStatusService();

        ReportsStatusServiceTicker.loadTicker({
          req: () => this.runReportsStatusService(),
        });

        this.getAppData();
      }
    },

    getAppData() {
      this.getEnums();
    },

    async runReportsStatusService() {
      await ReportsStatusService.refreshIncludingReportsIdsInQueue();
      await ReportsStatusService.checkReportsGenerationStatuses(this.showReportNotification);
    },

    showReportNotification(id) {
      if (!this.reportsNotifications.includes(id)) {
        this.reportsNotifications.push(id);
      }
    },

    closeReportNotification(id) {
      this.reportsNotifications.splice(this.reportsNotifications.indexOf(id), 1);
    },

    onLogout() {
      this.logout({ routeName: this.$route.name });
      this.$router.push({ name: 'login' });
    },
  },
};
