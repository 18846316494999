export default [
  {
    path: '/costs/invoice-scheduler',
    name: 'invoiceScheduler',
    component: () => import('./_views/IrInvoiceScheduler.vue'),
    meta: {
      title: 'Invoice Scheduler',
      group: 'costs',
      navigation: {
        name: 'Invoice Scheduler',
        to: {
          name: 'invoiceScheduler',
        },
      },
    },
  },
];
