var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"application-root-container"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.needMainApp)?_c('v-blacker-app-wrapper',{attrs:{"title":_vm.projectTitle,"username":_vm.userName,"navigation-config":_vm.navigationConfig,"route-info-config":_vm.routeInfoConfig,"bug-report-info":_vm.bugReportInfo,"hide-theme-color-switcher":""},on:{"click:logout":_vm.onLogout},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-blacker-layout',{attrs:{"justify-space-between":""}},[_vm._v(" "+_vm._s(_vm.copyright)+" "),_c('span',[_c('a',{staticStyle:{"margin-right":"5px"},attrs:{"href":_vm.serverUrl,"target":"_blank"}},[_vm._v(" API v."+_vm._s(_vm.apiVersion)+" ")]),_vm._v(" ("+_vm._s(_vm.uiVersion)+") ")])])]},proxy:true}],null,false,1317450141)},[_c('orders-top-search',{attrs:{"slot":"header-tools"},slot:"header-tools"}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1),_vm._l((_vm.reportsNotifications),function(reportNotificationId){return _c('ir-notification',{key:reportNotificationId,on:{"close":function($event){return _vm.closeReportNotification(reportNotificationId)}}},[_vm._v(" Report "),_c('router-link',{staticClass:"info--text mx-1",attrs:{"to":{
            name: 'reports',
            query: {
              pageNum: '1',
            },
          }}},[_c('span',{on:{"click":function($event){return _vm.closeReportNotification(reportNotificationId)}}},[_vm._v(" "+_vm._s(reportNotificationId)+" ")])]),_vm._v(" successfully generated ")],1)})],2):_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$route.name === 'forbidden')?_c('v-blacker-error',{attrs:{"error-code":"403","error-title":"Access Denied/Forbidden","buttons":[{ name: 'Logout', callback: _vm.onLogout }]}}):_vm._e(),(_vm.$route.name === 'error404')?_c('v-blacker-error',{attrs:{"buttons":[
          { name: 'Go back', callback: function () { return _vm.$router.go(-2); } },
          { name: 'Go home', callback: function () { return _vm.$router.push({ name: 'dashboard' }); } } ]}}):_vm._e(),_c('router-view',{attrs:{"name":"login"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }