import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import SERVER_URL from '@config/server';
import router from '@router';

import store from '@store';

export const API_POSTFIX = '/api/v1';

export const HTTP = axios.create({
  baseURL: `${SERVER_URL}${API_POSTFIX}`,
  headers: {
    Authorization: {
      toString() {
        return `bearer ${store.state.authenticator.token}`;
      },
    },
  },
  validateStatus(status) { // eslint-disable-line
    if (status === 401) {
      store.dispatch('authenticator/logout', {
        routeName: router.currentRoute.name,
      });
      router.push({ name: 'login' });
    }

    if (status === 403) {
      router.push({ name: 'forbidden' });
    }

    if (status >= 200 && status < 300) {
      return status;
    }
  },
});

Vue.use(VueAxios, HTTP);

export default HTTP;
