const IrMailing = () => import('./_views/IrMailing.vue');

export default [
  {
    path: '/shipping/mailing',
    name: 'mailing',
    component: IrMailing,
    meta: {
      title: 'Mailing',
      group: 'shipping',
      navigation: {
        name: 'Mailing ',
        to: {
          name: 'mailing',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
