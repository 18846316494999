import Vue from 'vue';
import Vuex from 'vuex';

import authenticator from './modules/authenticator';
import application from './modules/application';
import enums from './modules/enums';


Vue.use(Vuex);

const modules = {
  authenticator,
  application,
  enums,
};

// Automatic registration of store modules from @modules
const requireModule = require.context('@modules/', true, /\/store$/);
requireModule.keys().forEach((key) => {
  let moduleName;
  if (requireModule(key).namespace) {
    moduleName = requireModule(key).namespace;
  } else {
    const modulePath = key.match(/(?=\/)(.*)(?=\/store)/)[0].split('/');
    moduleName = modulePath[modulePath.length - 1];
  }
  modules[moduleName] = requireModule(key).default || requireModule(key);
});

export default new Vuex.Store({ modules });
