import store from '@store';

import Wrapper from './Wrapper.vue';

const Accounts = () => import('./_views/accounts');
const Account = () => import('./_views/account');
const NewAccount = () => import('./_views/newAccount');

const parentRouteConfig = {
  name: 'Accounts',
  to: {
    name: 'accounts',
    query: {
      pageNum: '1',
    },
  },
};

export default [
  {
    path: '/accounts',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'accounts',
        component: Accounts,
        meta: {
          title: 'Accounts',
          navigation: {
            position: 2,
            name: 'Accounts',
            icon: 'supervisor_account',
            to: {
              name: 'accounts',
              query: {
                pageNum: '1',
              },
            },
          },
        },
      },
      {
        path: 'edit/:id',
        name: 'account',
        component: Account,
        meta: {
          title: 'Edit Account',
          parent: parentRouteConfig,
          customDisplayedValue: () => store.state.accounts.account.accountFullName || 'Loading...',
        },
      },
      {
        path: 'add',
        name: 'accountCreator',
        component: NewAccount,
        meta: {
          title: 'Add Account',
          hideRouteParam: true,
          parent: parentRouteConfig,
        },
      },
    ],
  },
];
