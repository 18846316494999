export default [
  {
    path: '/costs/invoice-charges',
    name: 'invoiceCharges',
    component: () => import('./_views/IrInvoiceCharges.vue'),
    meta: {
      title: 'Invoice Charges',
      group: 'costs',
      navigation: {
        name: 'Invoice Charges',
        to: {
          name: 'invoiceCharges',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
