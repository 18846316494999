import { ReshipApiService } from './_services/reship.api.service';

import { createReshipOrder } from './_meta/reshipOrder';

export default {
  namespaced: true,

  state: {
    reshipOrderProviders: [createReshipOrder()],
  },

  mutations: {
    setReshipOrderProviders(state, payload) {
      state.reshipOrderProviders = payload;
    },
  },

  actions: {
    async getReshipOrderProviders({ commit }, params) {
      commit('setReshipOrderProviders', await ReshipApiService.getReshipOrderProviders({ ...params }));
    },
  },
};
