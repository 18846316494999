import HTTP from '@api';

import { generateXHRError } from '@helpers';

import { getTagsArrayFromQueryValue } from '../_helpers/tags';

export default {
  async getOrders({ commit }, {
    customerId = null, providerId = null, searchTerm = null,
    dates = null, routingRule = null, lowerPrices = null,
    tags = null, notInTags = null,
    pageNum = 1, pageSize = 10,
  }) {
    try {
      // Hack for .NET parser
      // axios format of arrays in query string (e.g. ?tags[]=a&tags[]=b) not allowed for .NET
      // for more information asks backend team
      const tagsStr = getTagsArrayFromQueryValue(tags).join('&tags=');
      const notInTagsStr = getTagsArrayFromQueryValue(notInTags).join('&notInTags=');

      let urlWithTagsQueryStr = 'orders';

      if (tagsStr) {
        urlWithTagsQueryStr += `?tags=${tagsStr}`;
      }

      if (notInTagsStr) {
        urlWithTagsQueryStr += `${urlWithTagsQueryStr === 'orders' ? '?' : '&'}notInTags=${notInTagsStr}`;
      }

      const { data = {} } = await HTTP.get(urlWithTagsQueryStr, {
        params: {
          customerId,
          providerId,
          searchTerm,
          startDate: dates && dates.length ? dates[0] : null,
          endDate: dates && dates.length ? `${dates[1]} 23:59:59` : null,
          routingRule,
          lowerPrices,
          pageNum,
          pageSize,
        },
      });
      commit('setOrders', data.items || []);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
  async getOrder({ commit }, { id }) {
    try {
      const { data = {} } = await HTTP.get(`orders/${id}`);
      commit('setOrder', data);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
  async getTrackingInfoByOrderId({ commit }, { id }) {
    try {
      const { data = [] } = await HTTP.get(`orders/${id}/tracking`);
      const items = data
        .map(({ body }) => {
          try {
            return JSON.parse(body);
          } catch (e) {
            return false;
          }
        })
        .filter(item => !!item);
      commit('setTrackingInfo', items);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
  async getOrdersStatistics({ commit }, { startDate, endDate }) {
    try {
      const { data = {} } = await HTTP.get('orders/statsdetails', {
        params: { startDate, endDate },
      });
      commit('setStatistics', data);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};
