import Wrapper from './_views/Wrapper.vue';

const IrComplaints = () => import('./_views/IrComplaints.vue');
const IrComplaint = () => import('./_views/IrComplaint.vue');

const parentRouteConfig = {
  name: 'Complaints',
  to: {
    name: 'complaints',
    query: {
      pageNum: '1',
    },
  },
};

export default [
  {
    path: '/complaints',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'complaints',
        component: IrComplaints,
        meta: {
          title: 'Complaints',
          navigation: {
            position: 6,
            name: 'Complaints',
            icon: 'assignment_return',
            to: {
              name: 'complaints',
              query: {
                pageNum: '1',
              },
            },
          },
        },
      },
      {
        path: ':id',
        name: 'complaint',
        component: IrComplaint,
        meta: {
          title: 'Complaint',
          parent: parentRouteConfig,
        },
      },
    ],
  },
];
