const Labels = () => import('./_views/labelsInformation/LabelsInformation.vue');

export default [
  {
    path: '/shipping/labels',
    name: 'labelsInfo',
    component: Labels,
    meta: {
      title: 'Labels',
      group: 'shipping',
      navigation: {
        name: 'Labels ',
        to: {
          name: 'labelsInfo',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
