export const createTicker = () => {
  const TICKER_KEY = Symbol('ticker');
  const tickerHandlers = new Map();

  let tickerInterval = null;

  const runTickers = async (req) => {
    const data = await req();

    if (!tickerHandlers.size) {
      return;
    }

    const handlers = tickerHandlers.get(TICKER_KEY) || [];

    handlers.forEach(fn => fn(data));
  };

  return {
    loadTicker: ({ intervalMSec = 10000, req = () => {} } = {}) => {
      tickerInterval = setInterval(() => runTickers(req), intervalMSec);
    },

    stopTicker() {
      clearInterval(tickerInterval);
    },

    destroyTicker: () => {
      clearInterval(tickerInterval);
      tickerHandlers.delete(TICKER_KEY);
    },

    subscribeToTicker: (cb) => {
      const subscribers = tickerHandlers.get(TICKER_KEY) || [];

      tickerHandlers.set(TICKER_KEY, [...subscribers, cb]);
    },

    unsubscribeFromTicker: (cb) => {
      if (cb) {
        const subscribers = tickerHandlers.get(TICKER_KEY) || [];
        tickerHandlers.set(TICKER_KEY, subscribers.filter(_cb => _cb !== cb));
      } else {
        tickerHandlers.delete(TICKER_KEY);
      }
    },
  };
};

export default {};
