import { mapState, mapActions } from 'vuex';

import { generateXHRError } from '@helpers';

export default {
  name: 'OrdersTopSearch',
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    ...mapState('orders', ['orders']),
  },
  methods: {
    ...mapActions('orders', ['getOrders']),
    async searchOrders() {
      if (!this.searchValue) {
        return;
      }
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.getOrders({ searchTerm: this.searchValue });
        this.$router.push(
          this.orders.length === 1
            ? {
              name: 'order',
              params: { id: this.orders[0].id },
            }
            : {
              name: 'orders',
              params: { searchTerm: this.searchValue },
            },
        );
        this.searchValue = '';
      } catch (e) {
        this.$VBlackerTheme.notification.error(generateXHRError(e));
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
  },
};
