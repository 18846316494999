import HTTP from '@api';

import { generateXHRError } from '@helpers';

export default {
  namespaced: true,
  state: {
    estimation: {},
    estimationHistory: [],
    estimationInputMessage: {},
    estimationRequestResult: {},
  },
  mutations: {
    setEstimationHistory(state, payload) {
      state.estimationHistory = payload;
    },
    setEstimationInputMessage(state, payload) {
      state.estimationInputMessage = payload;
    },
    setEstimation(state, payload) {
      state.estimation = payload;
    },
    setEstimationRequestResult(state, payload) {
      state.estimationRequestResult = payload;
    },
  },
  actions: {
    async getEstimationHistory({ commit }, {
      searchId = null, error = null, orderId = null,
      pageNum = 1, pageSize = 10,
    }) {
      try {
        const { data = [] } = await HTTP.get('shipping-estimations', {
          params: {
            error,
            searchId,
            orderId,
            pageNum,
            pageSize,
          },
        });
        commit('setEstimationHistory', data || []);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },

    async getEstimation({ commit }, { id }) {
      try {
        const { data = {} } = await HTTP.get(`shipping-estimations/${id}`);

        commit('setEstimation', data || {});

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },

    async getEstimationInputMessage({ commit }, { id }) {
      try {
        const { data = [] } = await HTTP.get(`shipping-estimations/${id}/message`);

        commit('setEstimationInputMessage', data || {});

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },

    async sendEstimationRequest({ commit }, { customer, request }) {
      try {
        const { data = {} } = await HTTP.post(`estimations/${customer}`, request);
        commit('setEstimationRequestResult', data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },

    clearEstimationRequestResult({ commit }) {
      commit('setEstimationRequestResult', {});
    },
  },
};
