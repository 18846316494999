import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const ReturnPackageApiService = {
  async getReturnedPackages({
    orderId = null, trackingNumber = null,
    printProviderId = null, printCustomerId = null,
    pageNum = 1, pageSize = 10,
  }) {
    try {
      const { data } = await HTTP.get('return-packages', {
        params: {
          orderId, trackingNumber, printProviderId, printCustomerId, pageNum, pageSize,
        },
      });

      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getReturnedPackage({ id }) {
    try {
      const { data } = await HTTP.get(`return-packages/${id}`);

      return data || {};
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getReturnedPackageHistoryItemRequest({ id, historyId }) {
    try {
      const { data } = await HTTP.get(`return-packages/${id}/history/${historyId}/request`);

      return data || '';
    } catch (e) {
      if (e.response.status === 404) {
        return Promise.resolve('');
      }

      return Promise.reject(generateXHRError(e));
    }
  },

  async addVendorReturnTrackingNumber({ id, trackingNumber }) {
    try {
      await HTTP.post(`return-packages/${id}/tracking/vendor`, { trackingNumber });

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
