import { FulfilmentApiService } from './_services/fulfilments.api.service';


export default {
  namespaced: true,

  state: {
    fulfilments: [],
  },

  mutations: {
    setFulfilments(state, payload) {
      state.fulfilments = payload;
    },
  },

  actions: {
    async getFulfilments({ commit }, params) {
      commit('setFulfilments', await FulfilmentApiService.getFulfilments({ ...params }));
    },
  },
};
