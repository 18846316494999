const Alerts = () => import('./_views/alerts');

export default [
  {
    path: '/alerts',
    name: 'alerts',
    component: Alerts,
    meta: {
      title: 'Alerts',
      navigation: {
        position: 5,
        name: 'Alerts',
        icon: 'warning',
        to: {
          name: 'alerts',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
