const TrackingInformation = () => import('./_views/tracking');

export default [
  {
    path: '/shipping/tracking',
    name: 'tracking',
    component: TrackingInformation,
    meta: {
      title: 'Tracking Information',
      group: 'shipping',
      navigation: {
        name: 'Tracking Information',
        to: {
          name: 'tracking',
          query: {
            pageNum: '1',
            state: 'all',
          },
        },
      },
    },
  },
];
