const IrOrderReturn = () => import('./_views/IrOrderReturn.vue');

export default [
  {
    path: '/shipping/return',
    name: 'return',
    component: IrOrderReturn,
    meta: {
      title: 'Return',
      group: 'shipping',
      navigation: {
        name: 'Return',
        to: {
          name: 'return',
        },
      },
    },
  },
];
