import HTTP from '@api';

import { generateXHRError } from '@helpers';

import { createReturnedOrder } from '../_meta/returnedOrder';

export const ReturnApiService = {
  async getReturnedOrder({ searchTerm }) {
    try {
      const { data } = await HTTP.get(`operations/${searchTerm}/return`);

      data.forEach((item) => {
        item.reference = item.printProviderOrderId;
      });

      return data || [createReturnedOrder()];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async sendReturnedOrder({ orderId, form }) {
    try {
      const { data } = await HTTP.post(`operations/${orderId}/return`, form);

      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
