const IN_PROGRESS_REPORTS_KEY = 'IN_PROGRESS_REPORTS';
const SELECTED_REPORT_TYPE_KEY = 'SELECTED_REPORT_TYPE_KEY';

const getIds = () => JSON.parse(window.localStorage.getItem(IN_PROGRESS_REPORTS_KEY) || '[]');

export const ReportsLocalStorageService = {
  getReportsIdsInProgress() {
    return getIds();
  },

  addReportIdToInProgress(reportId) {
    const ids = getIds();

    if (!ids.includes(reportId)) {
      ids.push(reportId);
      window.localStorage.setItem(IN_PROGRESS_REPORTS_KEY, JSON.stringify(ids));
    }

    return ids;
  },

  isReportIdInProgress(reportId) {
    const ids = getIds();

    return ids.includes(reportId);
  },

  removeReportIdFromInProgress(reportId) {
    const ids = getIds();

    if (ids.includes(reportId)) {
      ids.splice(ids.indexOf(reportId), 1);
      window.localStorage.setItem(IN_PROGRESS_REPORTS_KEY, JSON.stringify(ids));
    }

    return ids;
  },

  addSelectedReportType(type) {
    window.localStorage.setItem(SELECTED_REPORT_TYPE_KEY, type);
  },

  getSelectedReportType() {
    return window.localStorage.getItem(SELECTED_REPORT_TYPE_KEY) || '';
  },
};

export default {};
