import Wrapper from './Wrapper.vue';

const Orders = () => import('./_views/orders');
const Order = () => import('./_views/order');

const parentRouteConfig = {
  name: 'Orders',
  to: {
    name: 'orders',
    query: {
      pageNum: '1',
    },
  },
};

export default [
  {
    path: '/orders',
    component: Wrapper,
    children: [
      {
        path: '',
        name: 'orders',
        component: Orders,
        meta: {
          title: 'Orders',
          navigation: {
            position: 3,
            name: 'Orders',
            icon: 'shopping_cart',
            to: {
              name: 'orders',
              query: {
                pageNum: '1',
              },
            },
          },
        },
      },
      {
        path: ':id',
        name: 'order',
        component: Order,
        meta: {
          title: 'Order',
          parent: parentRouteConfig,
          stopDocumentTitleUpdate: true,
        },
      },
    ],
  },
];
