const Converter = () => import('./_views/converter');

export default [
  {
    path: '/settings/converter',
    name: 'converter',
    component: Converter,
    meta: {
      title: 'Converter',
      group: 'settings',
      navigation: {
        name: 'Converter',
        to: {
          name: 'converter',
        },
      },
    },
  },
];
