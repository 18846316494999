const getRouteDisplayedParam = ($route) => {
  const { customDisplayedParamKey, customDisplayedValue } = $route.meta;
  if (customDisplayedValue) {
    return customDisplayedValue();
  }
  if (customDisplayedParamKey) {
    return $route.params[customDisplayedParamKey];
  }
  return $route.params.id;
};

export const MAIN_ROUTE_NAME = 'dashboard';

export default ($route, mainRouteConfig) => {
  const { name: currentRouteName, meta: currentRouteMeta = {} } = $route;
  if (currentRouteName === mainRouteConfig.name) {
    return [{
      name: mainRouteConfig.title,
      isCurrentLink: true,
      disabled: true,
      to: {},
    }];
  }
  const ROUTE_INFO_CONFIG = [];
  ROUTE_INFO_CONFIG.push({
    name: mainRouteConfig.title,
    to: mainRouteConfig.to,
  });
  if (currentRouteMeta.parent) {
    ROUTE_INFO_CONFIG.push(
      {
        name: currentRouteMeta.parent.name,
        to: currentRouteMeta.parent.to,
      },
      {
        name: currentRouteMeta.title,
        isCurrentLink: true,
        disabled: true,
        to: {},
      },
    );
    if (!currentRouteMeta.hideRouteParam) {
      ROUTE_INFO_CONFIG.push({
        name: getRouteDisplayedParam($route),
        disabled: true,
        bold: true,
        to: {},
      });
    }
  } else {
    ROUTE_INFO_CONFIG.push({
      name: currentRouteMeta.title,
      isCurrentLink: true,
      disabled: true,
      to: {},
    });
  }
  return ROUTE_INFO_CONFIG;
};
