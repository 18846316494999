const InvoiceRecords = () => import('./_views/invoiceRecords');

export default [
  {
    path: '/costs/invoice-records',
    name: 'invoiceRecords',
    component: InvoiceRecords,
    meta: {
      title: 'Invoice Records',
      group: 'costs',
      navigation: {
        name: 'Invoice Records',
        to: {
          name: 'invoiceRecords',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
