export default [
  {
    path: '/costs/invoices',
    name: 'invoices',
    component: () => import('./_views/IrInvoices.vue'),
    meta: {
      title: 'Invoices',
      group: 'costs',
      navigation: {
        name: 'Invoices',
        to: {
          name: 'invoices',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
