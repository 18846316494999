export const createOrderPackage = () => ({
  length: null,
  width: null,
  height: null,
  weight: null,
});

export const createOrderPackageItem = () => ({
  weight: null,
  cost: null,
  description: null,
  comodityCode: null,
  countryOfOrigin: null,
});

export const createOrder = ({ addressKey = 'from' } = {}) => ({
  [addressKey]: {
    company: '',
    attention: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phoneNumber: '',
  },
  shipMethod: '',
  shipService: '',
  orderItems: [],
  packages: [createOrderPackage()],
  reference: '',
});
