import SERVER_URL from '@config/server';
import HTTP, { API_POSTFIX } from '@api';
import { decodeJWT, removeLastSlashFromUrl } from '@helpers';

const AUTH_SETTINGS_URL = `${removeLastSlashFromUrl(SERVER_URL)}${API_POSTFIX}/settings/system`;

export default {
  namespaced: true,
  state: {
    userManagerUrl: localStorage.getItem('userManagerUrl') || '',
    clientId: localStorage.getItem('clientId') || '',
    apiVersion: localStorage.getItem('apiVersion') || null,
  },
  mutations: {
    setUserManagerUrl(state, payload) {
      state.userManagerUrl = payload;
      localStorage.setItem('userManagerUrl', payload);
    },
    setClientId(state, payload) {
      state.clientId = payload;
      localStorage.setItem('clientId', payload);
    },
    setApiVersion(state, payload) {
      state.apiVersion = payload;
      localStorage.setItem('apiVersion', payload);
    },
  },
  actions: {
    async getSettings({ commit }) {
      try {
        const response = await HTTP.get(AUTH_SETTINGS_URL, { headers: {} });
        const { authSettings: { userManagerUrl = '', clientId }, version: apiVersion } = response.data;

        commit('setUserManagerUrl', removeLastSlashFromUrl(userManagerUrl));
        commit('setClientId', clientId);
        commit('setApiVersion', apiVersion);

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  getters: {
    userName: (state, getters, rootState) => (
      decodeJWT(rootState.authenticator.token).name
    ),
  },
};
