import Vue from 'vue';
import VueRouter from 'vue-router';

import { SHORT_PROJECT_NAME } from '@config/application';
import store from '@store';

const AppLogin = () => import('@components/appLogin');

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    components: {
      login: AppLogin,
    },
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/403',
    name: 'forbidden',
    meta: {
      title: 'Forbidden',
    },
  },
  {
    path: '/404',
    name: 'error404',
    meta: {
      title: 'Page not found',
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

// Automatic registration of routes from @modules
const requireRoute = require.context('@modules/', true, /\/route$/);
requireRoute.keys().forEach((key) => {
  const route = requireRoute(key).default || [];
  routes.push(...route);
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const initializeApp = (() => {
  let isInitialAction = true;
  return async ({ name, query }) => {
    if (!isInitialAction) {
      return Promise.resolve({});
    }
    isInitialAction = false;
    try {
      const { generatedQuery, replaceQuery } = await store.dispatch(
        'authenticator/generateToken',
        { query: { ...query } },
      );
      if (name === 'login') {
        return Promise.resolve({ query: { name: 'dashboard' } });
      }
      return Promise.resolve({ query: generatedQuery, replaceQuery });
    } catch (e) {
      const { response: { status } = {} } = e || {};
      if (status === 403) {
        return Promise.resolve({ isSpecialRoute: true, routeName: 'forbidden' });
      }
      return Promise.resolve({ isSpecialRoute: true, routeName: 'login' });
    }
  };
})();

router.beforeEach(async (to, from, next) => {
  const {
    query, routeName, isSpecialRoute, replaceQuery,
  } = await initializeApp(to);

  if (isSpecialRoute) {
    next({ name: routeName });
    return;
  }

  if (replaceQuery && !isSpecialRoute) {
    next({ name: to.name, params: to.params, query });
    return;
  }

  const { name, meta } = to;
  const { token } = store.state.authenticator;

  if (!meta.stopDocumentTitleUpdate) {
    document.title = `${meta.title} | ${SHORT_PROJECT_NAME}`;
  }

  if (name === 'forbidden') {
    next();
  } else if (token && name === 'login') {
    next({ name: 'dashboard' });
  } else if (!token && from.name === 'login' && name === 'login') {
    next(false);
  } else if (!token && name !== 'login') {
    next({ name: 'login' });
  } else {
    next();
  }
});

export function createRouter() {
  return router;
}

export default router;
