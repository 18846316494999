import HTTP from '@api';

import { generateXHRError } from '@helpers';

export default {
  async getLabelsInformation({
    pageNum = 1, pageSize = 10,
    orderId = null, trackingNumber = null, vendor = null,
  }) {
    try {
      const { data: { items } } = await HTTP.get('labels', {
        params: {
          pageNum, pageSize, orderId, trackingNumber, vendor,
        },
      });
      return Promise.resolve(items || []);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};
