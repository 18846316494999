import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const namespace = 'advancedSettings';

const sortSettings = settings => (settings || []).sort((first, second) => {
  const a = first.name[0];
  const b = second.name[0];

  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
});

export default {
  namespaced: true,
  state: {
    settings: [],
    settingsHistory: [],
    settingsHistoryTotalCount: 0,
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload;
    },
    setSettingsHistory(state, payload) {
      state.settingsHistory = payload;
    },
    setSettingsHistoryTotalCount(state, payload) {
      state.settingsHistoryTotalCount = payload;
    },
  },
  actions: {
    async getSettings({ commit }) {
      try {
        const { data } = await HTTP.get('settings/advanced');
        commit('setSettings', sortSettings(data));
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
    async getSettingsHistory({ commit }, { pageNum = 1, pageSize = 10 }) {
      try {
        const { data } = await HTTP.get('settings/advanced/history', {
          params: {
            pageNum, pageSize,
          },
        });
        commit('setSettingsHistory', data.items || []);
        commit('setSettingsHistoryTotalCount', data.total || 0);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
    async saveSettings({ state: { settings }, commit }) {
      try {
        const { data } = await HTTP.post('settings/advanced', settings);
        commit('setSettings', sortSettings(data));
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
  },
};
