import { ReturnPackageApiService } from './_services/returnPackages.api.service';

export default {
  namespaced: true,

  state: {
    returnedPackages: [],
    returnedPackage: {},
  },

  mutations: {
    setReturnedPackages(state, payload) {
      state.returnedPackages = payload;
    },

    setReturnedPackage(state, payload) {
      state.returnedPackage = payload;
    },
  },

  actions: {
    async getReturnedPackages({ commit }, params) {
      commit('setReturnedPackages', await ReturnPackageApiService.getReturnedPackages({ ...params }));
    },

    async getReturnedPackage({ commit }, params) {
      commit('setReturnedPackage', await ReturnPackageApiService.getReturnedPackage({ ...params }));
    },
  },
};
