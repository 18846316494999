const Advanced = () => import('./_views/advanced');

export default [
  {
    path: '/settings/advanced',
    name: 'advancedSettings',
    component: Advanced,
    meta: {
      title: 'Advanced',
      group: 'settings',
      navigation: {
        name: 'Advanced',
        to: {
          name: 'advancedSettings',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
