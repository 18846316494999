import HTTP from '@api';

import { generateXHRError } from '@helpers';

export default {
  namespaced: true,
  state: {
    alerts: [],
    alertsTotalCount: 0,
  },
  mutations: {
    setAlerts(state, payload) {
      state.alerts = payload;
    },
    setAlertsTotalCount(state, payload) {
      state.alertsTotalCount = payload;
    },
  },
  actions: {
    async getAlerts({ commit }, {
      customerId = null, providerId = null, searchTerm = null,
      dates = null, pageNum = 1, pageSize = 10,
    } = {}) {
      commit('setAlertsTotalCount', 0);
      try {
        const { data = {} } = await HTTP.get('alerts', {
          params: {
            customerId,
            providerId,
            searchTerm,
            startDate: dates && dates.length ? dates[0] : null,
            endDate: dates && dates.length ? `${dates[1]} 23:59:59` : null,
            pageNum,
            pageSize,
          },
        });
        commit('setAlertsTotalCount', data.total);
        commit('setAlerts', data.items || []);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
  },
};
