import Vue from 'vue';

import HTTP from '@api';

import { generateXHRError } from '@helpers';

import { getClients, getProviders, mapAccountsToOptions } from './_helpers';

export default {
  namespaced: true,
  state: {
    account: {},
    accounts: [],
    allAccounts: [],
    accountsTotalCount: 0,
  },
  mutations: {
    setAccount(state, payload) {
      state.account = payload;
    },
    setAccountXpressEnabled(state) {
      Vue.set(state.account, 'xpressEnabled', true);
    },
    setAccounts(state, payload) {
      state.accounts = payload;
    },
    setAllAccounts(state, payload) {
      state.allAccounts = payload;
    },
    setAccountsTotalCount(state, payload) {
      state.accountsTotalCount = payload;
    },
  },
  actions: {
    async getAccounts({ commit }, {
      activeOnly = true, nameToken = null, pageNum = 1, pageSize = 200,
    } = {}) {
      commit('setAccountsTotalCount', 0);
      try {
        const { data = {} } = await HTTP.get('accounts', {
          params: {
            activeOnly, nameToken, pageNum, pageSize,
          },
        });
        commit('setAccountsTotalCount', data.total);
        commit('setAccounts', data.items || []);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },

    async getAllAccounts({ commit }, { pageSize = 200 } = {}) {
      try {
        const { data = {} } = await HTTP.get('accounts', {
          params: { activeOnly: false, pageNum: 1, pageSize },
        });

        commit('setAllAccounts', data.items || []);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },


    async getAccount({ commit }, { id }) {
      commit('setAccount', {});
      try {
        const { data = {} } = await HTTP.get(`accounts/${id}`);
        data.bindingConfiguration = (data.bindingConfiguration || [])
          .sort((first, second) => {
            const a = first.name[0];
            const b = second.name[0];

            if (a < b) {
              return -1;
            }

            if (a > b) {
              return 1;
            }

            return 0;
          });
        commit('setAccount', data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
    async updateAccount({ state: { account }, commit }, { id, editableAccountData }) {
      try {
        const { data = {} } = await HTTP.post(`accounts/${id}`, {
          ...account,
          ...editableAccountData,
        });
        commit('setAccount', data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
  },
  getters: {
    clients: ({ accounts }) => getClients(accounts),
    providers: ({ accounts }) => getProviders(accounts),

    accountsOptions: ({ accounts }) => mapAccountsToOptions(accounts),
    clientsOptions: ({ accounts }) => mapAccountsToOptions(getClients(accounts)),
    providersOptions: ({ accounts }) => mapAccountsToOptions(getProviders(accounts)),

    accountNameById: ({ accounts }) => {
      const response = {};
      accounts.forEach(({ accountId, accountFullName }) => {
        response[accountId] = accountFullName;
      });
      return response;
    },

    accountNameByIdFromAllAccounts: ({ allAccounts }) => {
      const response = {};
      allAccounts.forEach(({ accountId, accountFullName }) => {
        response[accountId] = accountFullName;
      });
      return response;
    },
  },
};
