<template>
  <v-snackbar
    :value="true"
    :timeout="-1"
    shaped
    right
    top
  >
    <v-layout
      justify-space-between
      align-center
    >
      <v-icon
        :color="$attrs.color || 'success'"
        class="mr-n4"
      >
        {{ $attrs.icon || 'info_outlined' }}
      </v-icon>

      <slot />

      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon small>
          close
        </v-icon>
      </v-btn>
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  name: 'IrNotification',

  props: {
    msg: {
      type: String,
      default: '',
    },
  },
};
</script>
