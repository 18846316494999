import HTTP from '@api';

import { generateXHRError } from '@helpers';

export default {
  namespaced: true,
  state: {
    messages: [],
    message: '',
    messageRequest: '',
    messageResponse: '',
  },
  mutations: {
    setMessages(state, payload) {
      state.messages = payload;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    setMessageRequest(state, payload) {
      state.messageRequest = payload;
    },
    setMessageResponse(state, payload) {
      state.messageResponse = payload;
    },
  },
  actions: {
    async getMessages({ commit }, {
      senderId = null, messageId = null, searchTerm = null,
      dates = null, pageNum = 1, pageSize = 10,
    }) {
      try {
        const { data = {} } = await HTTP.get('messages', {
          params: {
            senderId,
            messageId,
            searchTerm,
            startDate: dates && dates.length ? dates[0] : null,
            endDate: dates && dates.length ? `${dates[1]} 23:59:59` : null,
            pageNum,
            pageSize,
          },
        });
        commit('setMessages', data.items || []);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
    async getMessage({ commit }, id) {
      commit('setMessage', '');
      try {
        const { data } = await HTTP.get(`messages/${id}/body`, {
          transformResponse: req => req,
        });
        commit('setMessage', data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
    async getMessageRequest({ commit }, id) {
      commit('setMessageRequest', '');
      try {
        const { data } = await HTTP.get(`messages/requests/${id}/body`, {
          transformResponse: req => req,
        });
        commit('setMessageRequest', data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
    async getMessageResponse({ commit }, id) {
      commit('setMessageResponse', '');
      try {
        const { data } = await HTTP.get(`messages/requests/${id}/response/body`, {
          transformResponse: req => req,
        });
        commit('setMessageResponse', data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(generateXHRError(e));
      }
    },
  },
};
