const RoutingRules = () => import('./_views/routingRules');

export default [
  {
    path: '/settings/routing-rules',
    name: 'routingRules',
    component: RoutingRules,
    meta: {
      title: 'Routing Rules',
      group: 'settings',
      navigation: {
        name: 'Routing Rules',
        to: {
          name: 'routingRules',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
