export const generateXHRError = (e) => {
  const { data } = e.response || {};
  if (data && typeof data === 'object' && data.message) {
    return data.message;
  }
  if (data && typeof data !== 'object') {
    return data;
  }
  return 'Sorry, something went wrong!';
};

export default {};
