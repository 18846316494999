export const useThrottle = (callback, limit) => {
  let timeOut = null;
  return () => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      callback();
    }, limit);
  };
};

export default {};
