export const getTagsArrayFromQueryValue = tags => (
  Array.isArray(tags || [])
    ? tags || []
    : [tags]
);

export const TAGS_HEATMAP = Object.freeze({
  popular: 'blue darken-3',
  regular: 'cyan darken-2',
  low: 'grey darken-2',
});

export const getTagHeatMapColorByPopularity = (popularity) => {
  if (popularity <= 0.01) {
    return TAGS_HEATMAP.low;
  }

  if (popularity <= 0.05) {
    return TAGS_HEATMAP.regular;
  }

  return TAGS_HEATMAP.popular;
};

export const useSortByAlphabet = valueName => (a, b) => {
  const aValue = (a[valueName] || '').toLowerCase();
  const bValue = (b[valueName] || '').toLowerCase();

  if (aValue < bValue) {
    return -1;
  }

  if (aValue > bValue) {
    return 1;
  }

  return 0;
};

/**
 * Generate display name, key and sort
 *
 * @param tags - tags array
 * @param category - category for key, use if no category in tags config
 * @return {*}
 */
export const prepareTagsData = (tags, category = null) => tags
  .map(tag => ({
    ...tag,
    tagName: tag.description || tag.tag,
    tagKey: `${category || tag.category}_${tag.tag}`,
    tagColor: getTagHeatMapColorByPopularity(tag.popularity),
  }))
  .sort(useSortByAlphabet('tagName'));

export default {};
