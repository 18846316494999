const IrReconciliation = () => import('./_views/IrReconciliation.vue');

export default [
  {
    path: '/costs/reconciliation',
    name: 'reconciliation',
    component: IrReconciliation,
    meta: {
      title: 'Reconciliation',
      group: 'costs',
      navigation: {
        name: 'Reconciliation',
        to: {
          name: 'reconciliation',
        },
      },
    },
  },
];
