import axios from 'axios';

import SERVER_URL from '@config/server';
import { API_POSTFIX } from '@api';
import { removeLastSlashFromUrl } from '@helpers';

const TOKEN_VALIDATION_URL = `${removeLastSlashFromUrl(SERVER_URL)}${API_POSTFIX}/auth/validate`;

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || null,
    customUrl: null,
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      if (payload) {
        localStorage.setItem('token', payload);
      } else {
        localStorage.removeItem('token');
      }
    },
    setCustomUrl(state, customUrl) {
      state.customUrl = customUrl;
    },
  },
  actions: {
    async generateToken({ state, commit, dispatch }, { query }) {
      const { token: TOKEN } = state;
      const { umToken: UM_TOKEN } = query;
      if (!UM_TOKEN && !TOKEN) {
        return Promise.reject();
      }

      try {
        const PARAMS = { headers: { Authorization: `bearer ${UM_TOKEN || TOKEN}` } };
        await axios.get(TOKEN_VALIDATION_URL, PARAMS);

        if (UM_TOKEN) {
          commit('setToken', UM_TOKEN);

          delete query.umToken;
          delete query.expires;

          return Promise.resolve({ generatedQuery: query, replaceQuery: true });
        }

        return Promise.resolve({ generatedQuery: {} });
      } catch (e) {
        dispatch('logout');
        return Promise.reject(e);
      }
    },
    login({ state, rootState }) {
      window.location = `${
        removeLastSlashFromUrl(rootState.application.userManagerUrl)
      }/OuterAccount/Login?service=${
        rootState.application.clientId
      }&returnUrl=${
        encodeURIComponent(state.customUrl || `${
          window.location.origin
        }/`)
      }`;
    },
    logout({ commit }, { routeName } = {}) {
      commit('setToken', null);
      if (routeName !== 'login' && routeName !== 'forbidden') {
        commit('setCustomUrl', window.location.href);
      }
    },
  },
};
