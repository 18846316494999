export default [
  {
    path: '/costs/statements',
    name: 'statements',
    component: () => import('./_views/IrStatements.vue'),
    meta: {
      title: 'Statements',
      group: 'costs',
      navigation: {
        name: 'Statements',
        to: {
          name: 'statements',
          query: {
            pageNum: '1',
          },
        },
      },
    },
  },
];
