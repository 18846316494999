const IrOrderReship = () => import('./_views/IrOrderReship.vue');

export default [
  {
    path: '/shipping/reship',
    name: 'reship',
    component: IrOrderReship,
    meta: {
      title: 'Reship',
      group: 'shipping',
      navigation: {
        name: 'Reship',
        to: {
          name: 'reship',
        },
      },
    },
  },
];
